import React from "react";
import "./friendItem.scss";
import { Link } from "react-router-dom";
const FriendItem = ({ friend }) => {
  return (
    <Link className="item" to={"/profile/"+friend.userId} style={{textDecoration:"none"}}>
    <div className="friendItem">
      <div className="profilePic">
        <img src={friend.userProfileImagePath} alt="" />
      </div>
      <div className="info">
        <span style={{fontWeight:"700"}}>{friend.userFirstName.charAt(0).toUpperCase()+friend.userFirstName.slice(1)} {friend.userLastName.charAt(0).toUpperCase()+friend.userLastName.slice(1)}</span>
        {/* <p>some bioo</p> */}
      </div>
    </div>
    </Link>
  );
};

export default FriendItem;