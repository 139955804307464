import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import "./login.scss";
import logo from '../../assets/logo5.jpg';

const Login = () => {
  const [inputs, setInputs] = useState({
    username: "",
    password: "",
  });
  const [err, setErr] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const [loginText, setLoginText] = useState(false);

  const navigate = useNavigate();

  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const { login } = useContext(AuthContext);

  const validate = () => {
    const errors = {};
    if (!inputs.username) {
      errors.username = "Username is required";
    }
    if (!inputs.password) {
      errors.password = "Password is required";
    }
    return errors;
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoginText(true);
    setErr(null);

    const errors = validate();
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      setLoginText(false);
      return;
    } else {
      setValidationErrors({});
    }

    try {
      await login(inputs);
      navigate("/");
    } catch (err) {
      setErr(err.message);
      setLoginText(false);
    }
    setLoginText(false);
  };

  return (
    <div className="login">
      <div className="card">
        <div className="left">
          <div className="outer">
            <div className="middle">
              <div className="inner">
                <div className="image">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="right">
          <div className="main">
            <div className="head">
              <img src={logo} width="150px" height="150px" alt="logo" />
              <p>Login</p>
            </div>
            <form>
              <div>
              <input
                type="text"
                placeholder="Username / mobile number"
                name="username"
                value={inputs.username}
                onChange={handleChange}
              />
              <div>
              {validationErrors.username && <span className="error">{validationErrors.username}</span>}
              </div>
              </div>
              <div>
              <input
                type="password"
                placeholder="Password"
                name="password"
                value={inputs.password}
                onChange={handleChange}
              />
              <div>
              {validationErrors.password && <span className="error">{validationErrors.password}</span>}
              </div>
              </div>
              <span className="error">{err && err}</span>

              <div className="options">
                <button onClick={handleLogin}>
                  {loginText ? (
                    <div className="loader">
                      <p>Logging in </p>
                      <div className="spinner"></div>
                    </div>
                  ) : "Login"}
                </button>
                <p>
                  <Link className="link-forgot" to="/forgot-password">Forgot Password?</Link>
                </p>
              </div>
            </form>
            <div>
              <p>Don't have an account? <Link className="link-signup" to="/register">Register</Link></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
