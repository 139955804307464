import React, { useState, useEffect } from 'react';
import axios from 'axios';

const FilterUserData = ({ onChangeYear, onChangeMonth, onChangeDistrict }) => {
  const currentYear = new Date().getFullYear();
  const [year, setYear] = useState(currentYear);
  const [month, setMonth] = useState('All');
  const [district, setDistrict] = useState('All');
  const [districts, setDistricts] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchDistricts = async () => {
      try {
        const response = await axios.get(apiUrl + "/ssakti/users/district/getAllDistricts");
        console.log(response)
        setDistricts(response.data.getAllDistrictData);
      } catch (err) {
        console.error(err);
      }
    };

    fetchDistricts();
  }, []);

  return (
    <div>
      <select value={year} onChange={(e) => { setYear(e.target.value); onChangeYear(e.target.value); }}>
        {/* Generate year options */}
        <option value={currentYear}>{currentYear}</option>
        <option value={currentYear - 1}>{currentYear - 1}</option>
        {/* Add more years if needed */}
      </select>
      <select value={month} onChange={(e) => { setMonth(e.target.value); onChangeMonth(e.target.value); }}>
        <option value="All">All</option>
        <option value="January">January</option>
        <option value="February">February</option>
        <option value="March">March</option>
        <option value="April">April</option>
        <option value="May">May</option>
        <option value="June">June</option>
        <option value="July">July</option>
        <option value="August">August</option>
        <option value="September">September</option>
        <option value="October">October</option>
        <option value="November">November</option>
        <option value="December">December</option>
      </select>
      <select value={district} onChange={(e) => { setDistrict(e.target.value); onChangeDistrict(e.target.value); }}>
     {districts.map((dis)=>{
      return <>
      <option>{dis.districtName}</option>
      </>
     })}
      </select>
    </div>
  );
};

export default FilterUserData;
