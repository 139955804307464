import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import image from "../../assets/register_image.jpg";
import user from "../../assets/user2.png";
import "./singlePage.scss";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ProfileTabsPages from "./ProfileTabsPages";
import { makeRequest } from "../../axios";
import AWS from "aws-sdk";
import { notification } from "antd";
import heic2any from "heic2any";
import SendIcon from "@mui/icons-material/Send";
import { AuthContext } from "../../context/authContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Image from "../../assets/img.png";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Modal } from "antd";
import { nativeSelectClasses } from "@mui/material";

const awsRegion = process.env.REACT_APP_AWS_REGION;
const awsSecretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
const awsAccessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;

function UserPage() {
  const params = useParams();
  // console.log(params)
  const [selectedTab, setSelectedTab] = useState("Posts");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const {currentUser} = useContext(AuthContext)

  const [file, setFile] = useState(null);
  const [desc, setDesc] = useState("");
  const S3_BUCKET = "streesakti";
  const [inputNameError, setInputNameError] = useState("");
  const [seletedModalComponent, setSeletedModalComponent] = useState(null);
  const [groupAbout,setGroupAbout] = useState({})
  const [groupMembers,setGroupMembers] = useState([])
  const [pendingFriends,setPendingFriends] = useState([])
  const [posts, setPosts] = useState([]);
  const [isMember,setIsMember] = useState(false)

  const navigate = useNavigate();

  const getGroupDetails = async () => {
    try {
      const response = await makeRequest.get(`/ssakti/users/pages/getPageDetails/${params.id}`);
      console.log(response)
      setGroupAbout(response.data.groupAbout || {});
      setGroupMembers(response.data.currentGroupMembers || []);
      let groupIdList = response.data.currentGroupMembers.map((grpMember)=>grpMember.userId)
      setIsMember(groupIdList.includes(currentUser.id))
      setPendingFriends(response.data.pendingFriends || []);
      setPosts(response.data.postDetails || []);
    } catch (error) {
      console.error("Error fetching group details:", error);
      notification.error({
        message: "Fetch Failed",
        description: "There was an error fetching the group details.",
      });
    }
  };

  
  useEffect(() => {
    getGroupDetails()
  }, [params.id])

  AWS.config.update({
    accessKeyId: awsAccessKeyId,
    secretAccessKey: awsSecretAccessKey,
    region: awsRegion,
  });

  const s3 = new AWS.S3();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (newPost) => {
      return makeRequest.post(
        `/ssakti/users/pages/addPost/${groupAbout.adminId}/${params.id}`,
        newPost
      );
    },
    {
      onSuccess: () => {
        getGroupDetails();
      //   queryClient.invalidateQueries(["posts"]);
      //   queryClient.invalidateQueries(["lastestActivities"]);
        notification.success({
          message: "Post uploaded successfully",
          description: "Your post has been shared with everyone.",
        });
      },
    }
  );

  const handleInput = (e) => {
    setDesc(e.target.value);
    if (e.target.value.length > 0) {
      setInputNameError("");
    }
  };

  const handleClick = async () => {
    if (desc.trim() === "") {
      setInputNameError("Description is required");
      return;
    }

    let fileToUpload = file;
    if (file && file.type === "image/heic") {
      try {
        const convertedBlob = await heic2any({
          blob: file,
          toType: "image/jpeg",
        });
        fileToUpload = new File(
          [convertedBlob],
          file.name.replace(/\.[^/.]+$/, ".jpg"),
          { type: "image/jpeg" }
        );
      } catch (error) {
        console.error("Error converting HEIC to JPEG:", error);
        notification.error({
          message: "Conversion Failed",
          description: `There was an error converting your HEIC image: ${error.message}`,
        });
        return;
      }
    }

    let postImageUrl = null;
    if (file) {
      const params = {
        Bucket: S3_BUCKET,
        Key: `posts/${fileToUpload.name}`,
        Body: fileToUpload,
      };

      try {
        const data = await s3.upload(params).promise();
        postImageUrl = data.Location;
      } catch (error) {
        console.error("Error uploading file:", error);
        notification.error({
          message: "Upload Failed",
          description: `There was an error uploading your file: ${error.message}`,
        });
        return;
      }
    }

    mutation.mutate({
      postName: desc,
      postImageUrl: postImageUrl,
      postType: file ? fileToUpload.type : null,
    });

    setFile(null);
    setDesc("");
  };

  const handleBack = () => {
    navigate(-1);
  };


  const handleModal = (component) => {
    setIsModalVisible(true);
    setSeletedModalComponent(component)
  }

  let content;
  switch (seletedModalComponent){
    case "updateGroup":
      content = <UpdateGroup groupAbout={groupAbout} getGroupDetails={getGroupDetails} setIsModalVisible={setIsModalVisible}/>
      break;
    case "groupSettings":
      content = <GroupSettings/>
      break;
  }
 

  const followPage=async()=>{
    try {
      const res = await makeRequest.post(`/ssakti/users/pages/followPage/${currentUser.id}/${params.id}`)
      console.log(res)
      notification.success({
        message: "Followed successfully",
        description: "You have successfully followed the group.",
      });
      getGroupDetails();
    } catch (error) {
      console.error("Error following page:", error);
      notification.error({
        message: "Follow Failed",
        description: "There was an error following the group.",
      });
    }
  }

  const unFollowPage=async()=>{
    try {
      const res = await makeRequest.delete(`/ssakti/users/pages/exitPage/${groupAbout.adminId}/${params.id}/${currentUser.id}`)
      console.log(res)
      notification.success({
        message: "Unfollowed successfully",
        description: "You have successfully unfollowed the group.",
      });
      getGroupDetails();
      // navigate('/userPage')
    } catch (error) {
      console.error("Error unfollowing page:", error);
      notification.error({
        message: "Unfollow Failed",
        description: "There was an error unfollowing the group.",
      });
    }
  }


  return (
    <div className="grpSingle" style={{ height: "90vh", overflow: "scroll" }}>
      <div className="goBack">
        <button onClick={handleBack} className="goBack">
          <ArrowBackIosIcon className="size-4" />
        </button>
      </div>
      <div>
        <img src={groupAbout.pageCoverProfileImagePath} alt="group" />
      </div>
      <div className="detailsGrp">
        <div className="head">
          <img src={groupAbout.adminUserProfileImagePath} width={"100px"} height={"100px"} />
        </div>
        <div className="invite">
          <h1>{groupAbout.pageName}</h1>
          {
            groupAbout.adminId !== currentUser.id && (
              isMember === true ? (
                <button className="button" onClick={unFollowPage}>UnFollow</button>
              ) : (
                <button className="button" onClick={followPage}>Follow</button>
              )
            )
          }
        </div>
        <div className="Tabs">
          <div className="profileTab">
            <div className="tabs">
              <button
                className={selectedTab === "Posts" ? "active" : ""}
                onClick={() => setSelectedTab("Posts")}
              >
                Posts
              </button>
              <button
                className={selectedTab === "AboutGroup" ? "active" : ""}
                onClick={() => setSelectedTab("AboutGroup")}
              >
                About
              </button>
              <button
                className={selectedTab === "Friends" ? "active" : ""}
                onClick={() => setSelectedTab("Friends")}
              >
                Members
              </button>
            </div>
          </div>
          {/* <div className="more">
            <button>
              <MoreVertIcon className="size-3" />
            </button>
          </div> */}
        </div>

        <div className="grpBody">
          <div className="leftBody">
            <ProfileTabsPages selectedTab={selectedTab} post={posts} userId={currentUser.id} data={groupAbout} />
          </div>
          <div className="rightBody">
            {
              groupAbout.adminId !== currentUser.id ? null : (
                <p onClick={()=> handleModal("updateGroup")}>Update Page</p>
              )
            }
          </div>
          <Modal
            visible={isModalVisible}
            // onOk={handleLogout}
            onCancel={() => setIsModalVisible(false)} 
            footer={null}
          >
         {content}
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default UserPage;

export const GroupSettings=()=>{
  return (
    <div>
      <h1>Group settings</h1>
    </div>
  )
}

export const UpdateGroup=({groupAbout,getGroupDetails,setIsModalVisible})=>{
  console.log("this is a group",groupAbout)

  const [newGroupName, setNewGroupName] = useState(groupAbout.pageName)
  const [newGroupDescription, setNewGroupDescription] = useState(groupAbout.pageDescription)
  const [newFile,setNewFile] = useState()
  const {currentUser} = useContext(AuthContext)

  const S3_BUCKET = "streesakti";

  const navigate= useNavigate()

  AWS.config.update({
    accessKeyId: awsAccessKeyId,
    secretAccessKey: awsSecretAccessKey,
    region: awsRegion,
  });

  const S3 = new AWS.S3();

  const handleGroupUpdate=async()=>{
    if(!newGroupName ||!newGroupDescription){
      alert("Please fill all the fields")
      return;
    }
    else{
      let imageGrp;
      if(newFile){
        const params = {
          Bucket: S3_BUCKET,
          Key: `groupCover/${newFile.name}`,
          Body: newFile,
        };
        const data = await S3.upload(params).promise();
        imageGrp=data.Location
      }
      else{
        imageGrp=groupAbout.groupCoverProfileImagePath
      }
      
      
      const response = await makeRequest.put(`ssakti/users/pages/updatePage/${groupAbout.adminId}/${groupAbout.pagesId}`, {
        pageName: newGroupName,
        pageDescription: newGroupDescription,
        pageCoverProfileImagePath: imageGrp,
      });
      console.log(response);
      if(response){
        notification.success({
          message: 'Page updated successfully',
        });
        // navigate(`/userpage`)
        setIsModalVisible(false);
        getGroupDetails();
      }
      else{
        notification.error({
          message: 'Error',
          description: "Failed to update page details.",
        });
      }
    }
  }

  return (
    <div>
      <h1 style={{margin:"20px"}}>Update Page</h1>
      <hr/>
      <div style={{display:"flex",flexDirection:'column',margin:'20px'}}>
      <label htmlFor="grpName" style={{fontWeight:"bold",fontSize:'1rem'}}>Change Page Name</label>
      <input id="grpName" style={{width:'80%',padding:'5px'}} name="newGroupName" value={newGroupName} onChange={(e)=>setNewGroupName(e.target.value)} type="text" />
      </div>
      <div style={{display:"flex",flexDirection:'column',margin:'20px'}}>
      <label htmlFor="grpName" style={{fontWeight:"bold",fontSize:'1rem'}}>Change Page Description</label>
      <input id="grpName" style={{width:'80%',padding:'5px'}} name="newGroupDescription" value={newGroupDescription} onChange={(e)=>setNewGroupDescription(e.target.value)} type="text" />
      </div>
      <div style={{display:"flex",flexDirection:'column',margin:'20px'}}>
      <label htmlFor="grpName" style={{fontWeight:"bold",fontSize:'1rem'}}>Change Cover photo</label>
      <input id="grpName" style={{width:'80%',padding:'5px'}} placeholder={groupAbout.groupDescription} type="file" onChange={(e)=>setNewFile(e.target.files[0])} />
      {
        newFile && <img src={URL.createObjectURL(newFile)} alt="" style={{width:'100px',height:'100px'}} />
      }
      </div>

      <button style={{backgroundColor:'#012e6b',color:"white",border:"none",padding:"10px",borderRadius:"5px",fontWeight:"700"}} onClick={handleGroupUpdate} >Update</button>
    </div>
  )
}