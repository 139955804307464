import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import FilterUserData from './FilterUserData';
import moment from 'moment';
import { makeRequest } from '../../../axios';

const UsersLineChart = () => {
  const currentMonth = moment().format('MMMM');
  const [filteredData, setFilteredData] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(currentMonth);
  const [district, setDistrict] = useState('All');
  const [data, setData] = useState([]);
console.log(currentMonth)
  useEffect(() => {
    const fetchUserLineChartData = async () => {
      const response = await makeRequest.get(`ssakti/admin/userLineChart`);
      setData(response.data.data);
    };

    fetchUserLineChartData();
  }, []);

  useEffect(() => {
    if (data.length) {
      let result = data.filter(d => new Date(d.date).getFullYear() === parseInt(year));
      if (month !== 'All') {
        const monthIndex = moment().month(month).format('M') - 1; // Month index in 0-11 format
        result = result.filter(d => new Date(d.date).getMonth() === monthIndex);
      }
      if (district !== 'All') {
        result = result.filter(d => d.district === district);
      }

      if (month === 'All') {
        result = fillMissingMonths(result, year);
      } else {
        result = fillMissingDates(result, year, month);
      }

      setFilteredData(result);
    }
  }, [year, month, district, data]);

  const aggregateDataByMonth = (data) => {
    const aggregatedData = data.reduce((acc, curr) => {
      const month = moment(curr.date).format('MMMM');
      if (!acc[month]) {
        acc[month] = { date: month, count: 0 };
      }
      acc[month].count += curr.count;
      return acc;
    }, {});

    return Object.values(aggregatedData);
  };

  const fillMissingDates = (data, year, month) => {
    const daysInMonth = moment(`${year}-${month}`, 'YYYY-MMMM').daysInMonth();
    const filledData = [];

    for (let day = 1; day <= daysInMonth; day++) {
      const date = moment(`${year}-${month}-${day}`, 'YYYY-MMMM-D').format('YYYY-MM-DD');
      const existingData = data.find(d => d.date === date);
      filledData.push({
        date,
        count: existingData ? existingData.count : 0,
      });
    }

    return filledData;
  };

  const fillMissingMonths = (data, year) => {
    const monthsInYear = moment.months();
    const filledData = [];

    for (let i = 0; i < monthsInYear.length; i++) {
      const month = monthsInYear[i];
      const existingData = data.find(d => moment(d.date).format('MMMM') === month);
      filledData.push({
        date: month,
        count: existingData ? existingData.count : 0,
      });
    }

    return filledData;
  };

  const formatXAxis = (tickItem) => {
    return month === 'All' ? tickItem : moment(tickItem).format('DD MMM');
  };

  if (!data.length) {
    return <div>No data found.</div>;
  }

  return (
    <div style={{width:"100%", height:"600px"}}>
      <FilterUserData onChangeYear={setYear} onChangeMonth={setMonth} onChangeDistrict={setDistrict} />
      <LineChart width={700} height={400} data={filteredData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" tickFormatter={formatXAxis} />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="count" stroke="red" />
      </LineChart>
    </div>
  );
};

export default UsersLineChart;
