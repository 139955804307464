import "./navbar.scss";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import WbSunnyOutlinedIcon from "@mui/icons-material/WbSunny";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { NotificationsOutlined } from "@mui/icons-material";
import { Link, useLocation } from "react-router-dom";
import { useContext, useState, useEffect, useCallback } from "react";
import { DarkModeContext } from "../../context/darkModeContext";
import { AuthContext } from "../../context/authContext";
import { useNavigate } from "react-router-dom";
import SearchItem from "./SearchItem";
import { debounce } from "lodash";
import { Modal } from "antd";
import { makeRequest } from "../../axios";
import logo_symbol from "../../assets/logo_symbol.png";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { yellow } from "@mui/material/colors";
import { CloseOutlined } from "@mui/icons-material";
import ChatAssistant from "../ai/ChatAssistant";

const Navbar = () => {
  const { toggle, darkMode } = useContext(DarkModeContext);
  const { currentUser, logout } = useContext(AuthContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAIModalVisible, setIsAIModalVisible] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isSearchResultVisible, setIsSearchResultVisible] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isMessagesPage = location.pathname === "/messages";
  const [notificationCount, setNotificationCount] = useState(0);
  const [isNotificationVisible, setIsNotificationVisible] = useState(false);
  const queryClient = useQueryClient();

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const fetchSearchResults = useCallback(
    debounce(async (query) => {
      try {
        const response = await makeRequest.get(
          `/ssakti/users/user/searchUser/${query}`
        );
        setSearchResults(response.data.searchedData);
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    }, 300),
    []
  );

  const handleSearchInputChange = (e) => {
    const value = e.target.value;
    setSearchInput(value);
    if (value.length > 0) {
      fetchSearchResults(value);
    } else {
      setSearchResults([]);
    }
  };

  useEffect(() => {
    if (searchInput.length === 0) {
      setSearchResults([]);
    }
  }, [searchInput]);

  const closeSearchResults = () => {
    setIsSearchResultVisible(false);
    setSearchInput(""); // Optional: Clear search input when closing
  };

  const {
    isLoading,
    isError,
    data: notifications,
  } = useQuery(["notification"], () => {
    return makeRequest
      .get(`/ssakti/users/notification/getAllNotification/${currentUser.id}`)
      .then((res) => {
        setNotificationCount(res?.data?.unreadNotificationCount || 0);
        return res.data.notificationDetails;
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          setNotificationCount(0);
          return [];
        }
      });
  });

  const notificationMutation = useMutation(
    (id) => {
      return makeRequest.put(
        "ssakti/users/notification/updateNotificationStatus/" + id
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("notification");
      },
      onError: () => {
        console.error("Failed to update notification status");
      },
    }
  );

  const handleNotification = () => {
    setIsNotificationVisible(!isNotificationVisible);
    notificationMutation.mutate(currentUser.id);
  };

  return (
    <div
      className={`navbar ${darkMode ? "dark" : "light"} ${
        isMessagesPage ? "messagePage" : ""
      }`}
    >
      <div className="left">
        <Link to="/" style={{ textDecoration: "none", height: "50px" }}>
          <img src={logo_symbol} style={{ width: "50px", height: "50px" }} alt="Logo" />
        </Link>
        <Link to="/">
          <HomeOutlinedIcon
            sx={{ color: darkMode == true ? "#fff" : '#000' }}
            aria-label="Home"
          />
        </Link>
        {darkMode ? (
          <WbSunnyOutlinedIcon
            sx={{ color: yellow[700] }}
            aria-label="Switch to Light Mode"
            onClick={toggle}
          />
        ) : (
          <DarkModeOutlinedIcon aria-label="Switch to Dark Mode" onClick={toggle} />
        )}
        <div className="notification" onClick={handleNotification}>
          <NotificationsOutlined aria-label="Notifications" />
          {notificationCount > 0 && <span className="badge">{notificationCount}</span>}
        </div>
        <div
          className={`notificationList ${isNotificationVisible ? "visible" : ""}`}
        >
          {notifications?.length > 0 ? (
            notifications.map((notification) => (
              <div key={notification.id} className="notificationItem">
                {notification.notificationMessage}
              </div>
            ))
          ) : (
            <p>No notifications available</p>
          )}
        </div>
        {isNotificationVisible && (
          <div className="notificationOverlay" onClick={() => setIsNotificationVisible(false)} />
        )}
        <div className={`search ${isSearchResultVisible ? "resultActive" : ""}`}>
          <SearchOutlinedIcon
            aria-label="Search"
            onClick={() => setIsSearchResultVisible(!isSearchResultVisible)}
          />
          <input
            type="text"
            placeholder="Search..."
            value={searchInput}
            onChange={handleSearchInputChange}
            onFocus={() => setIsSearchResultVisible(true)}
          />
          {isSearchResultVisible && (
            <>
              {/* <button className="closeButton" onClick={closeSearchResults}>
                
              </button> */}

              <CloseOutlined  onClick={closeSearchResults}/>
              <div className="searchResultsContainer">
                {searchResults.map((result) => (
                  <SearchItem key={result.id} friend={result} closeSearchResults={closeSearchResults} />
                ))}
              </div>
            </>
          )}
        </div>
      </div>

      <div className="right">
        <butto onClick={()=> setIsAIModalVisible(true)}>Ask Sakhi</butto>
        <button onClick={() => setIsModalVisible(true)}>Logout</button>
        <div className="user">
          <Link to={`/profile/${currentUser.id}`}>
            <img src={currentUser.profilePic} alt={currentUser.name} />
          </Link>
        </div>
      </div>
      <Modal
        title="Logout Confirmation"
        visible={isModalVisible}
        onOk={handleLogout}
        onCancel={handleCancel}
        footer={[
          <button
            style={{
              backgroundColor: "#e4002b",
              color: "white",
              border: "none",
              borderRadius: "5px",
              width: "4rem",
              height: "2rem",
              fontWeight: "600",
              fontSize: "0.9rem",
            }}
            key="logout"
            type="primary"
            onClick={handleLogout}
          >
            Logout
          </button>,
        ]}
      >
        <p>Are you sure you want to logout?</p>
      </Modal>

      <Modal
        // title="Sakhi AI"
        visible={isAIModalVisible}
        footer= {false}
        // onOk={handleLogout}
        onCancel={() => setIsAIModalVisible(false)}
       
      >
        <ChatAssistant />
      </Modal>
    </div>
  );
};

export default Navbar;
