import React, { useEffect, useRef, useState } from 'react';
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
const apiUrl = process.env.REACT_APP_API_URL;

const ChatWindow = ({ setSelectedFriend, selectedFriend, currentUser }) => {
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState("");
    const [isConnected, setIsConnected] = useState(false); // Track connection state
    const stompClientRef = useRef(null);

    useEffect(() => {
        const connect = () => {
            const socket = new SockJS(apiUrl + '/ws');
            const stompClient = Stomp.over(socket);

            stompClient.connect({}, () => {
                console.log('Connected');
                setIsConnected(true);
                const senderId = currentUser.id;
                const receiverId = selectedFriend.userId;

                stompClient.subscribe(`/user/${senderId}/queue/messages`, (message) => {
                    // console.log("Received message:", message.body);
                    const parsedMessage = JSON.parse(message.body);
                 
                        showMessage(parsedMessage);
                    
                });
            }, (error) => {
                console.error('Error connecting:', error);
                setIsConnected(false);
            });

            stompClientRef.current = stompClient;
        };

        const loadChatHistory = () => {
            const senderId = currentUser.id;
            const receiverId = selectedFriend.userId;
            console.log('Loading chat history')
            if (senderId && receiverId) {
                fetch(apiUrl + `/ssakti/users/chatmessage/history/${senderId}/${receiverId}`)
                    .then(response => response.json())
                    .then(messages => {
                        console.log("Loaded message history:", messages);
                        setMessages(messages);
                    })
                    .catch(error => console.error('Error fetching chat history:', error));
            }
        };

        const showMessage = (message) => {
            setMessages(prevMessages => [...prevMessages, message]);
        };

        connect();
        loadChatHistory();

        return () => {
            if (stompClientRef.current && isConnected) {
                stompClientRef.current.disconnect();
            }
        };
    }, [currentUser, selectedFriend]);

    const handleInputChange = (e) => {
        setInputMessage(e.target.value);
    };

    const handleSendMessage = () => {
        if (inputMessage.trim() !== "" && isConnected) {
            const senderId = currentUser.id;
            const receiverId = selectedFriend.userId;

            if (stompClientRef.current) {
                const message = {
                    sender: { userId: senderId },
                    receiver: { userId: receiverId },
                    content: inputMessage
                };

                stompClientRef.current.send('/app/chat', {}, JSON.stringify(message));
                // setMessages([...messages, { content: inputMessage, sender: "me" }]);
                setInputMessage("");
            }
        }
    };

    return (
        <>
            <div className="chat-messages">
                <div className="nameContainer">
                    <ArrowBackIcon onClick={() => setSelectedFriend(null)} />
                    <div>{selectedFriend.userFirstName + " " + selectedFriend.userLastName}</div>
                </div>
                {messages.map((message, index) => (
                    <div key={index} className={`message ${message.userId === currentUser.id  ? "me" : "friend"}`}>
                        <span className={`${message.sender.userId === currentUser.id ? "me" : "friend"}`}> {message.content} </span>
                    </div>
                ))}
            </div>
            <div className="input-container">
                <input
                    type="text"
                    value={inputMessage}
                    onChange={handleInputChange}
                    placeholder="Type a message..."
                />
                <button onClick={handleSendMessage}>Send</button>
            </div>
        </>
    );
};

export default ChatWindow;
