import "./adminLeftBar.scss";
import { AuthContext } from "../../../context/authContext";
import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import PersonIcon from '@mui/icons-material/Person';
import HomeOutlinedIcon from "@mui/icons-material/Home";
import MobileScreenShareIcon from '@mui/icons-material/MobileScreenShare';

const AdminLeftBar = () => {

  const { currentUser } = useContext(AuthContext);
  const location = useLocation();
  const isMessagesPage = location.pathname === '/messages';
  // const activePath = location.pathname.includes;
  if (!currentUser) {
    return <div>Loading...</div>; // Or handle the loading state appropriately
  }
  
  return (
    <div className={`adminLeftBar ${isMessagesPage ? "messagePage" : ""}`}>
      <div className="container">
        <div className={`menu ${isMessagesPage ? "messagePage" : ""}`}>
          <Link to={`/profile/${currentUser.id}`}>
            <div className="user">
              <img
                src={currentUser.profilePic}
                alt=""
              />
              <div className={isMessagesPage ? "messagePage" : ""}>
                <span >{currentUser.name}</span>
                <p>{currentUser.email}</p>
              </div>
            </div>
          </Link>
          <hr className="hr-top" />

          <div className={`item ${location.pathname== "/admin/dashboard" ? "active" : ""}`} >
            <Link to="/admin/dashboard" className={`item-link ${location.pathname== "/admin/dashboard" ? "active" : ""}`}>
              <HomeOutlinedIcon fontSize="medium"/>
              <span className={isMessagesPage ? "messagePage" : ""}>Dashboard</span>
            </Link>
          </div>

          <div className={`item ${location.pathname.includes("/admin/userManager") ? "active" : ""}`}>
            <Link to="/admin/userManager"  className={`item-link ${location.pathname.includes("/admin/userManager") ? "active" : ""}`}>
              <PersonIcon fontSize="medium"/>
              <span className={isMessagesPage ? "messagePage" : ""}>User Manager</span>
            </Link>
          </div>

          <div className={`item ${location.pathname.includes("/admin/postsmanager") ? "active" : ""}`}>
            <Link to="/admin/postsmanager"  className={`item-link ${location.pathname.includes("/admin/postsmanager") ? "active" : ""}`}>
              <MobileScreenShareIcon fontSize="medium"/>
              <span className={isMessagesPage ? "messagePage" : ""}>Posts Manager</span>
            </Link>
          </div>
          <div className={`item ${location.pathname.includes("/admin/advetisements") ? "active" : ""}`}>
            <Link to="/admin/advetisements"  className={`item-link ${location.pathname.includes("/admin/advetisements") ? "active" : ""}`}>
              <MobileScreenShareIcon fontSize="medium"/>
              <span className={isMessagesPage ? "messagePage" : ""}>Advetisements</span>
            </Link>
          </div>

        </div>
        <hr />
       
      </div>
    </div>
  );
};

export default AdminLeftBar;
