import React, { useContext, useState,useEffect } from 'react'
import './saved.scss'
import { AuthContext } from "../../context/authContext";
import { makeRequest } from "../../axios";
import { Pagination } from "antd";
import { Link } from "react-router-dom";
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { notification } from 'antd';

function Saved() {


    const {currentUser} = useContext(AuthContext)
    const [totalElements, setTotalElements] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5); // Default page size
  const [savedPost,setSavedPost] = useState([])
  const [hasPages, setHasPages] = useState(false);
  const [isSaved, setIsSaved] = useState(true)

    const getUserPages = async (page = 1, size = 5) => {
        try {
          const response = await makeRequest.get(`/ssakti/users/savepost/getSavedPost/${currentUser.id}`, {
            params: { page: page - 1, size },
          });
          console.log(response);
          setSavedPost(response.data.savedPostData);
          setTotalElements(response.data.totalElements || 0);
          setHasPages(true);
        } catch (error) {
          console.error(error);
          setHasPages(false);
        }
      };
    
      useEffect(() => {
        getUserPages(currentPage, pageSize);
      }, [currentPage, pageSize]);
    
      const onPageChange = (page, size) => {
        setCurrentPage(page);
        setPageSize(size);
      };

      const handleDelSavedPost=async(postId)=>{
        try{
          console.log(postId)
          const response = await makeRequest.delete(`/ssakti/users/savepost/deletSavedPost/${currentUser.id}/${postId}`)
          console.log(response)
          if(response.status === 200){
            notification.success({
              message: response.data.message,
            });
            getUserPages(currentPage, pageSize);
          }
        }
        catch(error){
          // console.log(err)
          notification.error({
            message: error.response.data.message,
          });
        }
      }

  return (
    <div className='savedPage'>
        {hasPages ? (
        <div>
          <div className="head">
            <h1>Saved Posts</h1>
          </div>
          <hr />
          <div className="userPost">
            {savedPost &&
              savedPost.map((page, index) => (
                <div className="post" key={index}>
                  <div className='postName'>
                    <img src={page.userProfileImageUrl} width={"40px"} height={"40px"}/>
                    <p>{page.userName}</p>
                  </div>
                  <img src={page.postImageURl} width={"350px"} height={"200px"} style={{objectFit:"cover"}}/>
                  <div className='actions'>
                    {
                        page.userReactStatus ?
                        <FavoriteIcon className='size-4'/>
                        :
                        <FavoriteBorderIcon/>
                    }
                    <BookmarkIcon className='size-4' onClick={()=>handleDelSavedPost(page.postId)}/>
                    </div>
                </div>
              ))}
          </div>
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={totalElements}
            onChange={onPageChange}
            showSizeChanger
            pageSizeOptions={[2,4,6,8,10]}
          />
        </div>
      ) : (
        <div className="noPages">
          <h1>Oops, no pages found.</h1>
          <div>
          <button>
            <Link to="/explorePages">Explore Pages!</Link>
          </button>
          <button>
            <Link to="/createPage">Create one for you!</Link>
          </button>
          </div>  
        </div>
      )}
    </div>
  )
}

export default Saved
