import React from 'react';
import Stories, { WithSeeMore } from 'react-insta-stories';
import moment from 'moment';
import { makeRequest } from '../../../axios';
import CusListing from './CusListing';

const CusComponent = ({ cusData, setCusModalVisible }) => {
  const isMobile = window.innerWidth < 786;
  return (

    <div >
      <Stories
        stories={cusData.map((story) => {
          return ({
            url: story.storyUrl,
            type: story.storyType,
            //  on click i want to call close function of see more
            header: {
              heading: story.userFirstName + " " + story.userLastName,
              subheading: moment(story.storyCreatedAt).fromNow(),
              profileImage: story.userProfileImagePath,
            },
            seeMore: ({ close , }) => {
              return <div style={{ width: "100%", 
              height: "100%", 
              backgroundColor: "white", 
              backgroundColor: "transparent", 
              display: "flex", 
              justifyContent: "center" }} 
              onClick={close}
              
              >

                <div 
                style={{
                  backgroundColor: "white",
                  height: "70%", width: "90%",
                  position: "absolute",
                  bottom: "0px", 
                  // padding: "15px",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.2)",
                  overflow:"hidden",
                }}
                onClick={(e) => e.stopPropagation()}
                >
                  <CusListing viewersData={story.usersWhoViewed} storyId={story.storyId} setCusModalVisible={setCusModalVisible}/>
                </div>
              </div>;
            },
          })
        })}
        defaultInterval={1500}
        width={isMobile ? "100vw" : 423}
        height={isMobile ? "100vh" :768}
      />
    </div>
  );
};

export default CusComponent;
