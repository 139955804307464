import "./postManager.scss"
import { makeRequest } from "../../axios";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { Image, notification } from 'antd';

const PostManager = ()=>{
    const queryClient = useQueryClient();

    const { isLoading, isError, data: posts } = useQuery(['getPostsList'], () => {
        return makeRequest.get('ssakti/admin/posts/getAllPosts').then((res) => {
            return res.data.postDetails;
        });
    })

    const mutation = useMutation((id) => {
        return makeRequest.delete("ssakti/admin/posts/deletpost" + id)
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries('getPostsList');
            notification.success({
                message: 'Post Deleted successfully',
                description: 'All the post and comments have been deleted',
              });
          
        },
        onError: () => {
            console.error('Failed to delete posts');
            
        },
        // onSettled: ()=>{
        //     queryClient.invalidateQueries('getPostsList');
        // }
        
    }
    )

    const deletePost = (postId) => {
        if (window.confirm('Are you sure you want to delete this user?')) {
            mutation.mutate(postId);
        }
    };

    if (isError) {
        return <p>Error fetching posts</p>;
    }

    if (isLoading) {
        return <p>Loading...</p>;
    }


    return (
        <div className="adminPostManager">
         <div className="post-list">
                <div className="heading-container">

                    <div className="image-heading">
                        <h3>User pic</h3>
                    </div>

                    <div className="info-heading">
                        <h3 className="info-heading-common">Name</h3>
                        <h3 className="info-heading-common">Date</h3>
                        <h3 className="info-heading-common">Discription</h3>
                        <h3 className="info-heading-common">Post</h3>
                    </div>

                    <div className="button-heading">
                        <h3>Action</h3>
                    </div>

                </div>
                {posts.map(post => (
                    <div key={post.id} className="post-card">
                        <div className="image-container">
                            <img src={post?.userProfileImagePath} />
                        </div>

                        <div className="post-info">
                           <div className="name-container info-container-common"><h4>user name</h4></div> 
                            <div className="email-container info-container-common"><p>email</p></div>
                            <div className="totalPost-container info-container-common"><p>{post.postName}</p></div>
                            {/* <div className="date-container info-container-common"><p>{user.userCreatedAt}</p></div> */}
                            <div className="totalPost-container info-container-common"><Image src={post.postImageUrl}/></div>
                        </div>
                        <div className="button-container">
                            <button onClick={() => deletePost(post.postId)}>Delete</button>
                        </div>

                    </div>
                ))}
            </div>
        </div>
    )
}

export default PostManager;