// import React, { useContext } from "react";
// import "./userPageCard.scss";
// import { Link } from "react-router-dom";
// import image from "../../assets/admin_img.jpg";
// import moment from "moment";
// import DeleteIcon from "@mui/icons-material/Delete";
// import { DarkModeContext } from "../../context/darkModeContext";
// import { makeRequest } from "../../axios";
// import { message, Modal } from "antd";

// function UserPageCard({ page }) {
//   console.log(page);
//   let desc = page.pageDescription.slice(0, 35);
//   console.log(desc);
//   const {darkMode} = useContext(DarkModeContext)

//   const deletePage=async(page)=>{
//     try{
//       const response = await makeRequest.delete(`/ssakti/users/pages/deletePage/${page.adminId}/${page.pagesId}`)
//       if(response){
//         message.success("Page deleted successfully");
//       }
//     }
//     catch(err){
//       console.error(err);
//       message.error("Failed to delete group");
//     }
//   }

//   return (
//     <div className="card">
//       <Link to={`/userpage/${page.pagesId}`}>
//         <img src={page.pageCoverProfileImagePath} alt="user" />
//         <div className="head">
//           <h3>{page.pageName}</h3>
//           <DeleteIcon
//           onClick={()=>deletePage(page)}
//             className="size-4"
//             sx={{ color: !darkMode ? "#000" : "#fff" }}
//           />
//         </div>
//         <p>{page.category}</p>
//         <h6>{desc + "..."}</h6>
//         <h6>
//           Created by {page.adminUserFirstName} {page.adminUserLastName}
//         </h6>
//         <h6>Created on {moment(page.pageCreatedAt).format("MMMM Do YYYY")}</h6>
//       </Link>
//     </div>
//   );
// }

// export default UserPageCard;


import React, { useContext, useState } from "react";
import "./userPageCard.scss";
import { Link } from "react-router-dom";
import image from "../../assets/admin_img.jpg";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";
import { DarkModeContext } from "../../context/darkModeContext";
import { makeRequest } from "../../axios";
import { message, Modal } from "antd";
import { AuthContext } from "../../context/authContext";

function UserPageCard({ page,getUserPages }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pageToDelete, setPageToDelete] = useState(null); // Track the page to delete

  const { darkMode } = useContext(DarkModeContext);
  const {currentUser} = useContext(AuthContext)

  const showModal = (page) => {
    setPageToDelete(page); // Set the page to delete
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setPageToDelete(null);
  };

  const deletePage = async () => {
    if (pageToDelete) {
      try {
        const response = await makeRequest.delete(`/ssakti/users/pages/deletePage/${pageToDelete.adminId}/${pageToDelete.pagesId}`);
        console.log(response)
        if (response.status === 200) {
          message.success("Page deleted successfully");
          // You might want to add some logic here to refresh or update the page list
          getUserPages();
        }
      } catch (err) {
        console.error(err);
        message.error("Failed to delete page");
      } finally {
        setIsModalVisible(false);
        setPageToDelete(null);
      }
    }
  };

  return (
    <div className="card">
      <div >
        <img src={page.pageCoverProfileImagePath || image} alt="user" />
        <div className="head">
          <h3>
            <Link to={`/userpage/${page.pagesId}`}>
            {page.pageName}
            </Link>
            </h3>
            {
              currentUser.id === page.adminId &&
          <DeleteIcon
            onClick={() => showModal(page)} // Show modal on click
            className="size-4"
            sx={{ color: !darkMode ? "#000" : "#fff" }}
          />
            }
        </div>
        <p>{page.category}</p>
        <h6>{page.pageDescription.slice(0, 35) + "..."}</h6>
        <h6>
          Created by {page.adminUserFirstName} {page.adminUserLastName}
        </h6>
        <h6>Created on {moment(page.pageCreatedAt).format("MMMM Do YYYY")}</h6>
      </div>

      <Modal
        title="Confirm Delete"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <button
            key="cancel"
            onClick={handleCancel}
            style={{
              backgroundColor: "#ccc",
              color: "#000",
              border: "none",
              borderRadius: "5px",
              width: "4rem",
              height: "2rem",
              fontWeight: "600",
              fontSize: "0.9rem",
            }}
          >
            Cancel
          </button>,
          <button
            key="delete"
            onClick={deletePage}
            style={{
              backgroundColor: "#e4002b",
              color: "white",
              border: "none",
              borderRadius: "5px",
              width: "4rem",
              height: "2rem",
              fontWeight: "600",
              fontSize: "0.9rem",
            }}
          >
            Delete
          </button>,
        ]}
      >
        <p>Are you sure you want to delete this page?</p>
      </Modal>
    </div>
  );
}

export default UserPageCard;
