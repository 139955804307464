import React, { useContext, useState } from "react";
import Post from "../../components/post/Post";
import AboutPage from "./AboutPage";
import FriendsListing from ".././friendsListing/FriendsListng"
// import "./profileTabs.scss"
// import './tabs.scss';
import { AuthContext } from "../../context/authContext";
import { makeRequest } from "../../axios";
import { notification } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import AWS from "aws-sdk";
import heic2any from "heic2any";
import SendIcon from "@mui/icons-material/Send";

const awsRegion = process.env.REACT_APP_AWS_REGION;
const awsSecretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
const awsAccessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;

const ProfileTabsPages = ({ userId, selectedTab ,data,post}) => {
  console.log(data);
  
  return (
    <div className="profileTabs">
    
      <div className="tabContent">
        {/* {selectedTab === "Posts" && <PagesPost post={post} data={data}/>} */}
        {selectedTab === "AboutGroup" && <AboutPage data={data}/>}
        {selectedTab === "Friends" && <MembersGroup userId={userId} />}
      </div>
    </div>
  );
};

export default ProfileTabsPages;


export const PagesPost = ({post,data})=>{
  // console.log('this is post :',post)
  const {currentUser} = useContext(AuthContext)
  
  const [file, setFile] = useState(null);
  const [desc, setDesc] = useState("");
  const S3_BUCKET = "streesakti";
  const [inputNameError, setInputNameError] = useState("");

  AWS.config.update({
    accessKeyId: awsAccessKeyId,
    secretAccessKey: awsSecretAccessKey,
    region: awsRegion,
  });

  const s3 = new AWS.S3();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (newPost) => {
      return makeRequest.post(
        `/ssakti/users/group/addPost/${currentUser.id}/${data.groupId}`,
        newPost
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["posts"]);
        queryClient.invalidateQueries(["lastestActivities"]);
        notification.success({
          message: "Post uploaded successfully",
          description: "Your post has been shared with everyone.",
        });
      },
    }
  );

  const handleInput = (e) => {
    setDesc(e.target.value);
    if (e.target.value.length > 0) {
      setInputNameError("");
    }
  };


  const handleClick = async () => {
    if (desc.trim() === "") {
      setInputNameError("Description is required");
      return;
    }

    let fileToUpload = file;
    if (file && file.type === "image/heic") {
      try {
        const convertedBlob = await heic2any({
          blob: file,
          toType: "image/jpeg",
        });
        fileToUpload = new File(
          [convertedBlob],
          file.name.replace(/\.[^/.]+$/, ".jpg"),
          { type: "image/jpeg" }
        );
      } catch (error) {
        console.error("Error converting HEIC to JPEG:", error);
        notification.error({
          message: "Conversion Failed",
          description: `There was an error converting your HEIC image: ${error.message}`,
        });
        return;
      }
    }

    let postImageUrl = null;
    if (file) {
      const params = {
        Bucket: S3_BUCKET,
        Key: `posts/${fileToUpload.name}`,
        Body: fileToUpload,
      };

      try {
        const data = await s3.upload(params).promise();
        postImageUrl = data.Location;
      } catch (error) {
        console.error("Error uploading file:", error);
        notification.error({
          message: "Upload Failed",
          description: `There was an error uploading your file: ${error.message}`,
        });
        return;
      }
    }

    mutation.mutate({
      postName: desc,
      postImageUrl: postImageUrl,
      postType: file ? fileToUpload.type : null,
    });

    setFile(null);
    setDesc("");
  };
  return(
    <>
     <div className="shareGrp">
              <div className="container">
                <div className="top">
                  <div className="left">
                    <img src={currentUser.profilePic} alt="" />
                    <textarea
                      rows={"3"}
                      type="text"
                      placeholder={`What's on your mind ${currentUser.name}?`}
                      onChange={handleInput}
                      value={desc}
                    />
                  </div>
                  <div className="right">
                    {file && file.type.startsWith("image/") && (
                      <img className="file" alt="" src={URL.createObjectURL(file)} />
                    )}
                    {file && file.type.startsWith("video/") && (
                      <video className="file" controls>
                        <source src={URL.createObjectURL(file)} type={file.type} />
                        Your browser does not support the video tag.
                      </video>
                    )}
                    {!file && <p>No file selected</p>}
                  </div>
                </div>
                {inputNameError && <span className="inputError">{inputNameError}</span>}
                <hr />
                <div className="bottom">
                  <div className="left">
                    <input
                      type="file"
                      id="file"
                      accept="image/*,video/*"
                      style={{ display: "none" }}
                      onChange={(e) => setFile(e.target.files[0])}
                    />
                    <label htmlFor="file">
                      <div className="item">
                        <img src={Image} alt="" />
                        <span>Add Image/Video</span>
                      </div>
                    </label>
                  </div>
                  <div className="rightGrp">
                    <button onClick={handleClick}>
                      Share
                      <SendIcon style={{ fontSize: "1.3rem" }} sx={{ color: "#000" }} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
    {
      post?.length == 0 ?
      <p>No posts found.</p>
      :
      post.map((post)=>{
        return(
          <Post post={post} key={post.postId}/>
        )
      })
    }
    </>
  )
}

export const MembersGroup=({groupMembers,data,getGroupDetails})=>{
  console.log("grp",groupMembers)
  console.log("data",data)
  const {currentUser} = useContext(AuthContext)
  console.log(currentUser)

  const removeUserFromGroupByAdmin=async(id)=>{
    try{
      const response = await makeRequest.delete(`/ssakti/users/group/exitGroup/${data.groupId}/${data.adminId}/${id}`)
      console.log(response)
      if(response){
        notification.success({
          message: 'User removed successfully',
        });
        // navigate(`/groups`)
        getGroupDetails();
      }
      else{
        notification.error({
          message: 'Error',
          description: "Failed to remove the user.",
        });
      }
      // console.log("user")
    }
    catch(error){
      console.log(error)
      notification.error({
        message: 'Error',
        description: "Failed to remove the user.",
      });
    }
  }

  return(
    <div className="members">
    <h1>Followers</h1>
    {groupMembers.length === 0 ? (
      <div>No followers for this page.</div>
    ) : (
      groupMembers.map((member) => (
        <div className="member" key={member.userId}>
          <div className="right">
            <img src={member.userProfileImagePath} alt="profilePic" />
            <h3>{member.userFirstName} {member.userLastName}</h3>
          </div>
          <div className="left">
            {currentUser.id === data.adminId && (
              <button onClick={() => removeUserFromGroupByAdmin(member.userId)} className="remove">
                Remove from group
              </button>
            )}
          </div>
        </div>
      ))
    )}
  </div>  
  )
}