import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./register.scss";
import axios from "axios";
import { message } from "antd";
import ReCAPTCHA from "react-google-recaptcha";
import logo from '../../assets/logo5.jpg';


const Register = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [inputs, setInputs] = useState({
    firstName: "",
    email: "",
    password: "",
    lastName: "",
    dob: "",
    address: "",
    district: "",
    mobNumber:''
  });
  const [errors, setErrors] = useState({});
  const [err, setErr] = useState(null);
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const isMobile = window.innerWidth <= 768;
  const [districts, setDistricts] = useState([]);
  const [captchaToken, setCaptchaToken] = useState(null);

  const [regText,setRegText] = useState(false);

  useEffect(() => {
    const fetchDistricts = async () => {
      try {
        const response = await axios.get(apiUrl + "/ssakti/users/district/getAllDistricts");
        console.log(response)
        setDistricts(response.data.getAllDistrictData);
      } catch (err) {
        console.error(err);
      }
    };

    fetchDistricts();
  }, [apiUrl]);

  const validate = () => {
    const newErrors = {};

    if (!inputs.firstName) newErrors.firstName = "First name is required";
    if (!inputs.lastName) newErrors.lastName = "Last name is required";
    if (!inputs.password) {
        newErrors.password = "Password is required";
    } else if (inputs.password.length < 6) {
        newErrors.password = "Password must be at least 6 characters";
    }
    if (!inputs.dob) newErrors.dob = "Date of birth is required";
    if (!inputs.address) newErrors.address = "Address is required"; 
    if (!inputs.district) newErrors.district = "District is required";
    if (!captchaToken) newErrors.captcha = "Please complete the CAPTCHA";
    if (!inputs.email && !inputs.mobNumber) {
        newErrors.email = "Either Email or Mobile Number is required";
        newErrors.mobNumber = "Either Email or Mobile Number is required";
    } else {
        if (inputs.email && !/\S+@\S+\.\S+/.test(inputs.email)) {
            newErrors.email = "Email is invalid";
        }
        if (inputs.mobNumber && !/^\d{10}$/.test(inputs.mobNumber)) {
            newErrors.mobNumber = "Mobile number is invalid";
        }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
};


  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    setErrors((prev) => ({ ...prev, [e.target.name]: "" })); // Clear errors as user types
  };

  const handleClick = async (e) => {
    e.preventDefault();
    
    if (!validate()) return;
    
    try {
      setRegText(true)
      await axios.post(apiUrl + "/ssakti/users/user/userRegister", {
        userFirstName: inputs.firstName,
        userLastName: inputs.lastName,
        userEmail: inputs.email,
        userGender: "FEMALE",
        userDateOfBirth: inputs.dob,
        userAddress: inputs.address,
        userProfileImagePath: "https://cdn.pixabay.com/photo/2020/07/01/12/58/icon-5359554_1280.png",
        userCoverProfileImagePath: "https://images.unsplash.com/photo-1528459584353-5297db1a9c01?q=80&w=1799&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        userPassword: inputs.password,
        districtId: inputs.district,
        userMobileNumber: inputs.mobNumber,
        userRole:"ROLE_USER"
      });
      setSuccess(true);
      messageApi.open({
        type: 'success',
        content: <RegistrationSuccess />,
        className: 'custom-class',
        style: isMobile ? successMobile : successWeb,
      });
      setTimeout(() => {
        navigate('/login');
      }, 5000);
    } catch (err) {
      setErr(err.response.data.message);
      setRegText(false)
    }
    setRegText(false)
  };

  const handleCaptchaChange = (value) => {
    setCaptchaToken(value);  // Update captcha token state
  };

  let successMobile = {
    marginTop: '10vh',
    height: "200px",
  };

  let successWeb = {
    marginTop: '10vh',
    marginRight: '50vw',
    height: "200px",
  };

  const RegistrationSuccess = () => {
    return (
      <div className="success">
        <h4>Registration Successful</h4>
        <p>You will be redirected to the login page in 5 seconds.</p>
      </div>
    );
  };

  return (
    <div className="register">
      {contextHolder}
      <div className="card">
        <div className="left">
        <div className="outer">
            <div className="middle">
            <div className="inner">
              <div className="image">
              </div>
            </div>
            </div>
          </div>
        </div>
        <div className="right">
        <div className="head">
          <img src={logo} alt="" width={"150px"} height={"150px"}/>
          <p>Register</p>
          </div>
          <form>
            <div className="input-wrapper">
              <div>
                <input
                  type="text"
                  placeholder="First Name"
                  name="firstName"
                  onChange={handleChange}
                />
                <span className="error">{errors.firstName}</span>
              </div>
              <div>
                <input
                  type="text"
                  placeholder="Last Name"
                  name="lastName"
                  onChange={handleChange}
                />
                <span className="error">{errors.lastName}</span>
              </div>
            </div>
            <div className="input-wrapper">
              <div>
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  onChange={handleChange}
                />
                <span className="error">{errors.email}</span>
              </div>
              <div>
                <input
                  type="date"
                  placeholder="Date of Birth"
                  name="dob"
                  onChange={handleChange}
                />
                <span className="error">{errors.dob}</span>
              </div>
            </div>
            <div className="input-wrapper">
              <div>
                <select
                  name="district"
                  value={inputs.district}
                  onChange={handleChange}
                >
                  <option value="">Select District</option>
                  {districts.map((district) => (
                    <option key={district.districtId} value={district.districtId}>
                      {district.districtName}
                    </option>
                  ))}
                </select>
                <span className="error">{errors.district}</span>
              </div>
              <div>
                <input
                  type="text"
                  placeholder="Address"
                  name="address"
                  onChange={handleChange}
                />
                <span className="error">{errors.address}</span>
              </div>
            </div>
            <div className="input-wrapper">
            <div>
              <input
                type="password"
                placeholder="Password"
                name="password"
                onChange={handleChange}
              />
              <span className="error">{errors.password}</span>
            </div>
            <div>
              <input
                type="text"
                placeholder="Mobile number"
                name="mobNumber"
                onChange={handleChange}
              />
              <span className="error">{errors.mobNumber}</span>
            </div>
            </div>
            <div>
            <ReCAPTCHA
              sitekey="6Lc0qiMqAAAAAMKMV2oMuDzB2j9rKx3uD4Kg5rxy"
              onChange={handleCaptchaChange}
            />
            <span className="error">{errors.captcha}</span>
            </div>
            <span className="error">{err && err}</span>
            {success && "Registration is successful. Redirecting to login..."}
            <button onClick={handleClick}>{regText?
          (<div className="loader">
            <p>Registering </p>
            <div className="spinner"></div>
          </div>)
          :"Register"}</button>
          </form>
          <p>Already have an account? <Link className="link-signup" to="/login">Login </Link></p>
        </div>
      </div>
    </div>
  );
};

export default Register;
