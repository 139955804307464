import "./share.scss";
import Image from "../../assets/img.png";
import { useContext, useState } from "react";
import { AuthContext } from "../../context/authContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { makeRequest } from "../../axios";
import AWS from 'aws-sdk';
import { notification } from 'antd';
import heic2any from "heic2any";
import SendIcon from '@mui/icons-material/Send';
import { DarkModeContext } from "../../context/darkModeContext";

const awsRegion = process.env.REACT_APP_AWS_REGION;
const awsSecretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
const awsAccessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;

const Share = () => {
  const [file, setFile] = useState(null);
  const [desc, setDesc] = useState("");
  const [isUploading, setIsUploading] = useState(false); // Initialize uploading state
  const { toggle, darkMode } = useContext(DarkModeContext);
  const S3_BUCKET = 'streesakti';
  const [inputNameError, setInputNameError] = useState("");

  AWS.config.update({
    accessKeyId: awsAccessKeyId,
    secretAccessKey: awsSecretAccessKey,
    region: awsRegion
  });

  const {darkmode} = useContext(DarkModeContext)
  const s3 = new AWS.S3();
  const { currentUser } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (newPost) => {
      return makeRequest.post(`/sskati/users/posts/addPost/${currentUser.id}`, newPost);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["posts"]);
        queryClient.invalidateQueries(["lastestActivities"]);
        notification.success({
          message: 'Post uploaded successfully',
          description: 'Your post has been shared with everyone.',
        });
      },
      onSettled: () => {
        setIsUploading(false);
      }
    }
  );

  const handleInput = (e) => {
    setDesc(e.target.value);
    if (e.target.value.length > 0) {
      setInputNameError("");
    }
  };

  const handleClick = async () => {


    setIsUploading(true); // Set uploading state to true when the upload starts

    let fileToUpload = file;
    if (file && file.type === "image/heic") {
      try {
        const convertedBlob = await heic2any({ blob: file, toType: "image/jpeg" });
        fileToUpload = new File([convertedBlob], file.name.replace(/\.[^/.]+$/, ".jpg"), { type: "image/jpeg" });
      } catch (error) {
        console.error("Error converting HEIC to JPEG:", error);
        notification.error({
          message: 'Conversion Failed',
          description: `Error converting your HEIC image: ${error.message}`,
        });
        setIsUploading(false);
        return;
      }
    }
  
    let postImageUrl = null;
    if (fileToUpload) {
      const params = {
        Bucket: S3_BUCKET,
        Key: `posts/${fileToUpload.name}`,
        Body: fileToUpload,
      };
  
      try {
        const data = await s3.upload(params).promise();
        postImageUrl = data.Location;
      } catch (error) {
        console.error("Error uploading file:", error);
        notification.error({
          message: 'Upload Failed',
          description: `Error uploading your file: ${error.message}`,
        });
        setIsUploading(false);
        return;
      }
    }
  
    mutation.mutate({
      postName: desc || null, 
      postImageUrl: postImageUrl || null,
      postType: file ? fileToUpload.type : null,
      videoThumbnailUrl: null,
    });
  
    setFile(null);
    setDesc('');
    setIsUploading(false);
  };
  

  return (
    <div className="share">
      <div className="container">
        <div className="top">
          <div className="left">
            <img src={currentUser.profilePic} alt="" />
            <textarea
              rows={"3"}
              type="text"
              placeholder={`What's on your mind ${currentUser.name}?`}
              onChange={handleInput}
              value={desc}
              disabled={isUploading} // Disable input during upload
            />
          </div>
          <div className="right">
            {file && file.type.startsWith('image/') && (
              <img className="file" alt="" src={URL.createObjectURL(file)} />
            )}
            {file && file.type.startsWith('video/') && (
              <video className="file" controls>
                <source src={URL.createObjectURL(file)} type={file.type} />
                Your browser does not support the video tag.
              </video>
            )}
            {!file && <p>No file selected</p>} {/* Message when no file is selected */}
          </div>
        </div>
        {inputNameError && <span className="inputError">{inputNameError}</span>}
        <hr />
        <div className="bottom">
          <div className="left">
            <input
              type="file"
              id="file"
              accept="image/*,video/*"
              style={{ display: "none" }}
              onChange={(e) => setFile(e.target.files[0])}
              disabled={isUploading} // Disable file input during upload
            />
            <label htmlFor="file">
              <div className="item">
                <img src={Image} alt="" />
                <span>Add Image/Video</span>
              </div>
            </label>
          </div>
          <div className="right">
            <button onClick={handleClick} disabled={isUploading}>
              {isUploading ? "Sharing..." : "Share"}
              {!isUploading && <SendIcon style={{fontSize:"1.3rem"}} sx={{ color:!darkmode ? "#fff" :"#000" }} />}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Share;
