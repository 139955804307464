import "./adminDashboard.scss";
import UsersLineChart from "../../components/admin/analytics/usersLineChart";
import UserAndPostsData from "../../components/admin/analytics/UsersAndPostsData";
const AdminDashboard = () => {
    return (
      <div className="adminDashboard">
      <h2>Dashboard</h2>
      <UserAndPostsData/>
      <UsersLineChart/>
      </div>
    )
  }
  
  export default AdminDashboard;