import React from 'react';
import Stories from 'react-insta-stories';
import moment from 'moment';
import { makeRequest } from '../../../axios';

const StoriesModal = ({ setOpenStories, selectedStories, currentUser }) => {
  const isMobile = window.innerWidth < 786;

  return (
    <div>
      <Stories
        stories={selectedStories.storyDetails.map((storiesList) => {
          // Mark story as viewed
          makeRequest.post(`ssakti/users/story/addViewedStory/${currentUser.id}/${storiesList.storyId}`);

          // Prepare the story object with the correct media type
          return {
            url: storiesList.storyUrl,
            // type: storiesList.storyType === 'video' ? 'video' : 'image', // Explicitly set type for videos
            header: {
              heading: `${selectedStories.userFirstName} ${selectedStories.userLastName}`,
              subheading: moment(storiesList.storyCreatedAt).fromNow(),
              profileImage: selectedStories.userProfileImagePath,
            },
            seeMore: storiesList.storyType === 'video' ? (
              <video
                controls
                src={storiesList.storyUrl}
                style={{ width: '100%', height: 'auto' }}
              />
            ) : null,
          };
        })}
        defaultInterval={1500}
        width={isMobile ? '100vw' : 423}
        height={isMobile ? '100vh' : 768}
      />
    </div>
  );
};

export default StoriesModal;
